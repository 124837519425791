* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bgLogin {
  background-image: url(./images/First\ Block.png);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.bgLoginForm {
  background-image: url(./images/Group\ 1.png) !important;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.bgMobilModal {
  background-image: url(./images/Group\ 1.png) !important;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.container {
  max-width: 1422px;
  width: 90%;
  margin: 0px auto;
}
.textLimitCards {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.textLimitCardsModalTitle {
  overflow: hidden;
  display: -webkit-box;
  max-width: 500px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.modalCardSwiper .swiper-pagination {
  width: 100%;
  background: #d3d3d3;
  border-radius: 7px;
  height: 2px;
  display: flex;
  margin: 0;
  padding: 0;
  bottom: 0;
}
.modalCardSwiper .swiper-pagination-bullet {
  width: 50%;
  height: 2px;
  border-radius: 7px;
  background: #d3d3d3;
  margin: 0;
  padding: 0;
}
.modalCardSwiper .swiper-pagination-bullet-active {
  width: 50%;
  height: 2px;
  background: #9b9b9b;
}
.ant-modal {
  width: 90% !important;
  max-width: 1422px !important;
}
.ant-modal-content {
  border-radius: 10px !important;
}
.modalAnimation {
  animation: show-modal 0.3s forwards;
}
@keyframes show-modal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modaFilter {
  pointer-events: none !important;
}
/* .muiInputLogin{

} */

@media (max-width: 939.5px) {
  .textLimitCards {
    display: none;
  }
  .swiper-slide-show {
    display: none !important;
  }
  .ant-modal {
    top: 0 !important;
    padding-bottom: 0px !important;
    margin: 0px auto !important;
  }
  .bgLogin {
    background: #464d68;
  }
  .bgLoginForm {
    background: none;
  }
}
.anticon {
  vertical-align: 4px !important;
}

.ant-modal-content {
  padding: 0 24px !important;
}
